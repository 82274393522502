import React from "react"
import { Link } from "gatsby"
import { kebabCase } from "lodash"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

const FeaturedSection = ({ articles }) => {
  // 1. Helper for rendering Rich Text inline links
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        if (node.data.uri.includes("https://")) {
          return (
            <a href={node.data.uri} target="_blank" rel="noreferrer">
              {node.content[0].value}
            </a>
          )
        } else {
          return (
            <Link to={node.data.uri}>
              {node.content[0].value}
            </Link>
          )
        }
      },
    },
  }

  // 2. Check if rightSideBar has content (title or links)
  const hasRightSideBar =
    articles?.rightSideBar?.title ||
    (articles?.rightSideBar?.links?.length > 0)

  // 3. Check if newPatients has content (title, body, image, or link)
  const hasNewPatients =
    articles?.newPatients &&
    (
      articles.newPatients.title ||
      articles.newPatients?.content?.content ||
      articles.newPatients?.image?.file?.url ||
      (articles.newPatients?.linkUrl && articles.newPatients?.linkLabel)
    )

  // 4. If either the rightSideBar or newPatients has content, we render the right column
  const hasSidebarContent = hasRightSideBar || hasNewPatients

  return (
    <section className="article-feature-sec first-feature-sec">
      <div className="container">
        <div className="row">
          
          {/* Left Column: goes full width if there's NO sidebar content */}
          <div className={`col ${hasSidebarContent ? "col-md-8" : "col-md-12"}`}>
            {renderRichText(articles.introText, options)}
          </div>

          {/* Right Column: only render if there's something to show */}
          {hasSidebarContent && (
            <div className="col col-md-4 article-feature-inner">
              {articles?.rightSideBar?.title && (
                <h5>{articles.rightSideBar.title}</h5>
              )}

              {articles?.rightSideBar?.links?.length > 0 && (
                <ul className="sidebar-ul">
                  {articles.rightSideBar.links.map((item) => (
                    <li key={item.id}>
                      <Link to={item.url}>
                        <p>{item.label}</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.243"
                          height="13.501"
                          viewBox="0 0 20.243 13.501"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-round-forward"
                            data-name="Icon ionic-ios-arrow-round-forward"
                            d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                            transform="translate(-7.875 -11.252)"
                            fill="#2a3369"
                          />
                        </svg>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}

              {articles?.newPatients && (
                <div className="article-feature-box">
                  {/* Image */}
                  {articles?.newPatients?.image?.file?.url && (
                    <img src={articles.newPatients.image.file.url} alt="#" />
                  )}

                  <div className="article-feature-box-inner">
                    {articles?.newPatients?.title && (
                      <h6>{articles.newPatients.title}</h6>
                    )}
                    {articles?.newPatients?.content?.content && (
                      <p>{articles.newPatients.content.content}</p>
                    )}
                    {articles?.newPatients?.linkUrl &&
                     articles?.newPatients?.linkLabel && (
                      <Link to={articles.newPatients.linkUrl}>
                        {articles.newPatients.linkLabel}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.12"
                          height="10.084"
                          viewBox="0 0 15.12 10.084"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-round-forward"
                            data-name="Icon ionic-ios-arrow-round-forward"
                            d="M17.517,11.445a.686.686,0,0,0-.005.966l3.193,3.2H8.552a.683.683,0,0,0,0,1.365H20.7l-3.193,3.2a.691.691,0,0,0,.005.966.68.68,0,0,0,.961-.005L22.8,16.775h0a.767.767,0,0,0,.142-.215.652.652,0,0,0,.053-.263.685.685,0,0,0-.194-.478L18.473,11.46A.669.669,0,0,0,17.517,11.445Z"
                            transform="translate(-7.875 -11.252)"
                            fill="#ff0245"
                          />
                        </svg>
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

        </div>
      </div>
    </section>
  )
}

export default FeaturedSection

