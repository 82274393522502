import React from "react"
import { Link } from "gatsby"
// 1) Import from Contentful
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

const OfferedServicesSurgeries = ({ offer, surgery }) => {
  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const url = node.data.uri || ""

        if (url.startsWith("http")) {
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          )
        } else {
          return <Link to={url}>{children}</Link>
        }
      },
    },
  }

  return (
    <section
      className={`service-surgery-sec ${
        offer.weOfferImage ? "fullWidthWeOffer" : ""
      }`}
    >
      <div className="container">
        <div className="row">
          {/* Title + excerptNew */}
          <div className="col">
            <h2>{offer.title}</h2>

            {/* 3) Render the Rich Text field */}
            {offer.excerptNew && renderRichText(offer.excerptNew, options)}
          </div>

          {/* Image columns */}
          <div className="col col-md-6 service-surgery-img">
            <div className="service-surgery-img1">
              <img src={offer.image1.file.url} alt="#" />
            </div>
            <div className="service-surgery-img2">
              <img src={offer.image2.file.url} alt="#" />
            </div>
          </div>

          {/* Services Surgeries Links */}
          <div className="col col-md-6 service-surgery-content">
            {offer.servicesSurgeriesLinks.map(item => (
              <div key={item.id}>
                <Link
                  to={item.url}
                  style={{
                    fontWeight: "600",
                    margin: "0 0 1rem",
                    color: "#ff1d59",
                    borderBottom: "2px solid #ff1d59",
                    width: "fit-content",
                    textDecoration: "none",
                  }}
                >
                  {item.label}
                </Link>
                {/* If each item also has a Rich Text excerpt, you can render it similarly */}
                {item.excerptNew && renderRichText(item.excerptNew, options)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default OfferedServicesSurgeries
